import React from "react";
import { BsCCircle } from "react-icons/bs";

const Credentials = () => {
  return (
    <div>
      <p
        style={{
          fontSize: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <BsCCircle style={{ margin: "3px" }} />
        2024 Conserj.ru, Все права защищены.
      </p>
    </div>
  );
};

export default Credentials;
