import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import "./Action.css";
import { Link } from "react-router-dom";

const Action = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.1, // Trigger when 10% of the section is in view
  });

  return (
    <section
      className="section-col section-padding-x section-padding-y"
      id="action"
    >
      <div ref={ref} className="action-content">
        <h2>
          Серж <span>берет на себя</span> бронирование и подбор ресторанов,
          чтобы вы могли заниматься тем, что вам
          <span> действительно важно</span>.
        </h2>
          <button className={`call-to-action ${inView ? "shine" : ""}`} t>
            <a href="#hero" style={{ color: "var(--white)"}}>К бронированию
            </a>
            </button>

      </div>
    </section>
  );
};

export default Action;
