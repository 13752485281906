import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { logo } from "../assets/images";

import {
  TelegramLogin,
  HeroAuth,
  Navbar,
  Footer,
  Credentials,
} from "../imports";
import "./Homepage.css";
const HomepageAuth = () => {
  const [show, setShow] = useState(true);
  const navigate = useNavigate();
  let lastScrollY = window.scrollY;

  const handleLogout = () => {
    localStorage.removeItem("isLoggedIn"); 
    navigate("/");
    window.location.reload(); 
  };

  const controlNavbar = () => {
    if (window.scrollY > lastScrollY && window.scrollY > 0) {
      setShow(false); 
    } else if (window.scrollY < lastScrollY || window.scrollY === 0) {
      setShow(true); 
    }
    lastScrollY = window.scrollY;
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  return (
    <>
      <header className={show ? "header--show" : "header--hide"}>
        <img src={logo} alt="logo" height={35} width={30} />
        <Navbar />
        <div className="login-buttons">
          <Link to="/">
            <button className="gradient-border" id="btn-to-logout" onClick={handleLogout}>
              Log Out
            </button>
          </Link>
        </div>
      </header>

      <main>
        <HeroAuth />
      </main>

      <footer>
        <Footer />
        <Credentials />
      </footer>
    </>
  );
};

export default HomepageAuth;