import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { logo } from "../assets/images";

import {
  TelegramLogin,
  Advantages,
  Navbar,
  Ads,
  How,
  Hero,
  Price,
  Action,
  Footer,
  Credentials,
} from "../imports";
import "./Homepage.css";
const Homepage = () => {
  const [show, setShow] = useState(true);
  let lastScrollY = window.scrollY;

  const controlNavbar = () => {
    if (window.scrollY > lastScrollY && window.scrollY > 0) {
      setShow(false); 
    } else if (window.scrollY < lastScrollY || window.scrollY === 0) {
      setShow(true); 
    }
    lastScrollY = window.scrollY;
  };
  const handleLogin = () => {
    localStorage.setItem("isLoggedIn", 'true'); 
    window.location.reload(); 
  };


  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  return (
    <>
      <header className={show ? "header--show" : "header--hide"}>
        <img src={logo} alt="logo" height={35} width={30} />
        <Navbar />
        <div className="login-buttons">
            <button className="gradient-border" id="btn-to-signup" onClick={handleLogin}>
              Sign Up
            </button>
        </div>
      </header>

      <main>
        <Hero />
        <Ads />
        <How />
        <Advantages />
        <Price />
        <Action />
      </main>

      <footer>
        <Footer />
        <Credentials />
      </footer>
    </>
  );
};

export default Homepage;