import React, { useState, useEffect } from "react";
import "./Navbar.css";

const Navbar = ({ show }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (!show) {
      setIsOpen(false);
    }
  }, [show]);

  return (
    <>
      <div className="navbar">
        <div className="hamburger" onClick={toggleDropdown}>
          &#9776; {/* Hamburger icon */}
        </div>
        <nav className={`nav-links ${isOpen ? "open" : ""}`}>
          <ul>
            <li>
              <a href="#how">Как это работает?</a>
            </li>
            <li>
              <a href="#advantages">Преимущества</a>
            </li>
            <li>
              <a href="#price">Прайс</a>
            </li>
            <li>
              <a href="#contacts">Контакты</a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
