import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Process.css'; // Create a CSS file for styling

const Process = () => {
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("Серж уже занимается бронированием");
  const navigate = useNavigate();

  const handleContinue = () => {
    // console.log("Continue")
    navigate('/');
  }

  useEffect(() => {
    let intervalId = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(intervalId);
          setMessage("Готово! Сообщение о брони отправлено вам в Телеграм");
          return 100;
        }
        return prevProgress + 50; 
      });
    }, 1000); 

    return () => clearInterval(intervalId); 
  }, []);

  return (
    <section id='progress'>
      <div className="process-container">
            <p>{`${progress}%`}</p>
          <div className="progress-bar-container">
            <div className="progress-bar" style={{ width: `${progress}%` }} />
          </div>
        <p>{message}</p>
        
        <button className="btn-filled" onClick={handleContinue}>
              Продолжить
        </button>
            
      </div>
    </section>
  );
};

export default Process;