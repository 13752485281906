import React from 'react'
import { Link } from 'react-router-dom'

const Booking = () => {
  return (
    <>
    <h1>Booking</h1>
    <Link to='/'>
        <button>назад</button>
    </Link></>
    
  )
}

export default Booking