import React, { useState } from "react";
import "./Hero.css";
import { heroImg, heroImgMobile, heroImgTablet } from "../../assets/images";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const getCurrentTime = () => {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, '0'); 
  const minutes = String(now.getMinutes()).padStart(2, '0'); 
  return `${hours}:${minutes}`; 
};
const TimeInput = () => {
  return (
    <input
      type="time"
      defaultValue={getCurrentTime()} 
      className="hero-from-time"
    />
  );
};


const Hero = () => {
  const [place, setPlace] = useState("");
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [time, setTime] = useState(getCurrentTime());
  const [guests, setGuests] = useState(2);
  const navigate = useNavigate(); // Initialize useNavigate

  const handleSubmit = (event) => {
    event.preventDefault();
    if (place && date && time && guests) {
      console.log("Form Data:", { place, date, time, guests });
      navigate('/process'); 
    } else {
      alert("Please fill in all fields.");
    }
  };

  return (
    <section
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
      className="section-padding-x section-padding-y"
      id="hero"
    >     
            <p>Новое бронирование</p>

      <form className="hero-form" onSubmit={handleSubmit} style={{ width: "100%", margin: "0"}}>
        <input
          type="text"
          placeholder="Место"
          className="hero-from-place"
          value={place}
          onChange={(e) => setPlace(e.target.value)}
        />
        <input
          type="date"
          defaultValue={date}
          className="hero-from-date"
          min={new Date().toISOString().split("T")[0]}
          onChange={(e) => setDate(e.target.value)}
        />
        <TimeInput time={time} setTime={setTime} />
        <input
          type="number"
          min="1"
          defaultValue={guests}
          placeholder="Гостей"
          className="hero-from-guests"
          onChange={(e) => setGuests(parseInt(e.target.value))}
        />
        <button type="submit" className="btn-purple">
          Засержить
        </button>
      </form>
      <div style={{ margin: "2rem 0"}}>
        <h2 style={{ fontSize: "30px"}}>Мои бронирования</h2>
            <ul style={{ padding: "0"}}>
                <li style={{ width: "100%", border: "1px solid var(--bright-purple)", borderRadius: '1.5rem', padding: "1rem", margin: "10px 0"}}>
                    <h3>Название ресторана</h3>
                    <p>Дата: 23.02.2022, Время: 18:00</p>
                    <p>Количество гостей: 4</p>
                </li>
                <li style={{ width: "100%", border: "1px solid var(--bright-purple)", borderRadius: '1.5rem', padding: "1rem"}}>
                    <h3>Название ресторана</h3>
                    <p>Дата: 23.02.2022, Время: 18:00</p>
                    <p>Количество гостей: 4</p>
                </li>
            </ul>
      </div>
        
        
    </section>
  );
};

export default Hero;