import React, { useEffect, useRef } from "react";
import "./Advantages.css";
import { adv1, adv2, adv3, adv4 } from "../../assets/images";

const advantagesData = [
  {
    title: "Online бронирование",
    text: "Забронируйте столик в лучших заведениях города, избегая длительного ожидания и очередей. Вы всегда будете в центре событий и наслаждаться самыми востребованными местами.",
    img: adv1,
    spanText: "избегая длительного ожидания",
    className: "item1",
  },
  {
    title: "Быстро и просто",
    text: "Процесс бронирования занимает всего несколько минут, избавляя вас от сложных процессов и лишних хлопот.",
    img: adv3,
    spanText: "несколько минут",
    className: "item2",
  },
  {
    title: "Гибкость и адаптивность",
    text: "Наш сервис подстраивается под ваши нужды и предпочтения. Если в выбранном вами заведении нет свободных столиков, мы всегда предложим похожее место, где можно забронировать столик. Если вы не знаете, куда пойти, мы подберем заведение, идеально соответствующее вашим предпочтениям.",
    img: adv4,
    spanText: "подстраивается под ваши нужды",
    className: "item3",
  },
  {
    title: "Никаких звонков и длительных ожиданий",
    text: "С нашим сервисом вам не придется обзванивать десятки заведений в поисках свободного столика. Мы сделаем всю работу за вас, предоставив свободные места в нужном вам месте и времени.",
    img: adv2,
    spanText: "Мы сделаем всю работу за вас",
    className: "item4",
  },
];

const Advantages = () => {
  const itemsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.3 }
    );

    itemsRef.current.forEach((item) => item && observer.observe(item));

    return () => {
      itemsRef.current.forEach((item) => item && observer.unobserve(item));
    };
  }, []);

  return (
    <section
      id="advantages"
      className="section-padding-x section-col section-margin-y"
    >
      <h2 className="gradient-text">Преимущества сервиса</h2>
      <ul>
        {advantagesData.map(
          ({ title, text, img, spanText, className }, index) => (
            <li
              key={index}
              className={`grid-item ${className}`}
              ref={(el) => (itemsRef.current[index] = el)}
            >
              <article>
                <h3>{title}</h3>
                <div>
                  <p>
                    {text.split(spanText)[0]}
                    <span>{spanText}</span>
                    {text.split(spanText)[1]}
                  </p>
                </div>
                <img src={img} alt={title} />
              </article>
            </li>
          )
        )}
      </ul>
    </section>
  );
};

export default Advantages;
