import React, { useEffect, useRef } from "react";
import "./Ads.css";

const Ads = ({ categories = ["Рестораны", "Бары", "Кафе", "Кальянные"] }) => {
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const numberOfDuplicates = 4;

  useEffect(() => {
    const container = containerRef.current;
    const content = contentRef.current;

    if (!container || !content) return;

    const contentWidth = content.offsetWidth;
    let currentPosition = 0;

    const animateCategories = () => {
      currentPosition -= 1;
      if (Math.abs(currentPosition) >= contentWidth / numberOfDuplicates) {
        currentPosition = 0;
      }
      content.style.transform = `translateX(${currentPosition}px)`;
      requestAnimationFrame(animateCategories);
    };

    animateCategories();
  }, [categories, numberOfDuplicates]);

  const generateContent = () => {
    let items = [];
    for (let i = 0; i < numberOfDuplicates; i++) {
      items = items.concat(
        categories.map((category, index) => (
          <span key={`${i}-${index}`} className="category">
            {category}
          </span>
        ))
      );
    }
    return items;
  };

  return (
    <div className="ads-container" ref={containerRef}>
      <div className="ads-content" ref={contentRef}>
        {generateContent()}
      </div>
    </div>
  );
};

export default Ads;
