import React, { useEffect, useRef } from "react";
import "./How.css";
import { Typewriter } from "react-simple-typewriter";

import { step2, step3, searchIcon } from "../../assets/images";

const How = () => {
  const itemsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.3 }
    );

    itemsRef.current.forEach((item) => item && observer.observe(item));

    return () => {
      itemsRef.current.forEach((item) => item && observer.unobserve(item));
    };
  }, []);

  return (
    <section
      className="section-margin-y section-padding-x section-col"
      id="how"
    >
      <h2>Как работает Серж?</h2>
      <dl className="how-steps">
        <div className="step" ref={(el) => (itemsRef.current[0] = el)}>
          <div className="step-content">
            <dt>ШАГ 1</dt>
            <dd>
              Укажите название ресторана или опишите,{" "}
              <span>как вы хотите провести время</span>. Если необходимо,
              уточните бронирование (время / количество гостей / специальные
              пожелания)
            </dd>
          </div>
          <div className="typewriter">
            <div className="typewriter-text">
              <span style={{ color: "var(--grey)" }}>
                <Typewriter
                  words={[
                    "въетнамская кухня на китай-городе",
                    "бургерная чистые пруды",
                  ]}
                  loop={1000}
                  cursor
                  cursorStyle="|"
                  typeSpeed={100}
                  deleteSpeed={50}
                  delaySpeed={700}
                />
              </span>
            </div>

            <div className="typewriter-img">
              <img src={searchIcon} alt="search icon" />
            </div>
          </div>
        </div>

        <div className="step" ref={(el) => (itemsRef.current[1] = el)}>
          <div className="step-content">
            <dt>ШАГ 2</dt>
            <dd>
              Серж занимается <span>бронированием</span>: при наличии мест —
              бронирует, при отсутствии — предлагает альтернативы.
            </dd>
          </div>
          <div className="step-img-container item1">
            <img src={step2} alt="step 2" />
          </div>
        </div>

        <div className="step" ref={(el) => (itemsRef.current[2] = el)}>
          <div className="step-content">
            <dt>ШАГ 3</dt>
            <dd>
              Заведение забронировано, а вам остается только прийти и
              наслаждаться выбранным местом и временем!
            </dd>
          </div>
          <div className="step-img-container item2">
            <img src={step3} alt="step 3" />
          </div>
        </div>
      </dl>
    </section>
  );
};

export default How;
