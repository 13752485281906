import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Homepage, HomepageAuth, Booking, Process, SignUp } from "./imports";
var perf = require("./template.htm");

export default function App() {
  const isLoggedIn = localStorage.getItem("isLoggedIn");

  return (
    <BrowserRouter>
      <Routes>
        {isLoggedIn ? (
          <>
            <Route path="/" element={<HomepageAuth />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Homepage />} />
          </>
        )}
        <Route path="/process" element={<Process />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/signup" element={<SignUp />} />
      </Routes>
    </BrowserRouter>
  );
}
