import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import {
  telegramIcon,
  vkIcon,
  instIcon,
  mailIcon,
} from "../../../assets/images";

const Footer = () => {
  return (
    <>
      <div className="footer-content">
        <Link to="/policy">
          <p>Политика конфиденциальности</p>
        </Link>

        <Link to="/rules">
          <p>Правила использования</p>
        </Link>
        <p>Сообщить об ошибке</p>
        <div className="social-icons" id="contacts">
          <img src={telegramIcon} alt="telegramIcon" />
          <img src={instIcon} alt="instIcon" />
          <img src={vkIcon} alt="vkIcon" />
          <img src={mailIcon} alt="mailIcon" />
        </div>
      </div>
    </>
  );
};

export default Footer;
