import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./SignUp.css";
// Validation schema using Yup
const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
});

const SignUp = () => {
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await fetch("http://localhost:8000/api/signup/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });

      if (response.ok) {
        console.log("Sign up successful!");
        // Handle successful signup (e.g., redirect to login, show success message)
      } else {
        console.error("Sign up failed.");
        // Handle errors (e.g., show error messages)
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <section style={{ justifyContent: "center" }} className="section-col">
      <div className="signup-form">
        {/* <h2>Sign Up</h2> */}
        <Formik
          initialValues={{ email: "" }}
          validationSchema={SignupSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <h2>Sign Up</h2>
              <p>
                Введите вашу электронную почту, а мы отправим вам код для входа
              </p>
              <div>
                <Field
                  type="email"
                  name="email"
                  placeholder="example@gmail.com"
                />
                <ErrorMessage name="email" component="div" />
              </div>

              <button
                type="submit"
                disabled={isSubmitting}
                style={{ width: "100%" }}
              >
                Отправить
              </button>
            </Form>
          )}
        </Formik>
        <div className="alternative-signup">
          <p>Войти с помощью</p>
          <div>
            <button>Telegram</button>
            <button>Google</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignUp;
