import React, { useState, useEffect } from "react";
import "./Price.css";
import {
  IoIosCheckmarkCircle,
  IoIosCheckmarkCircleOutline,
} from "react-icons/io";

const Price = () => {
  const calculateTimeLeft = () => {
    const targetDate = new Date("2024-10-01T00:00:00");
    const now = new Date();
    const difference = targetDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        months: Math.floor(difference / (1000 * 60 * 60 * 24 * 30)),
        days: Math.floor((difference / (1000 * 60 * 60 * 24)) % 30),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const TimeUnit = ({ value, label }) => {
    return (
      <div className="time-unit">
        <div className="time-left-container">
          {String(value)
            .padStart(2, "0")
            .split("")
            .map((digit, i) => (
              <div key={i} className="time-left-rectangle">
                {digit}
              </div>
            ))}
        </div>
        <p>{label}</p>
      </div>
    );
  };

  return (
    <section
      className="section-col section-padding-x section-padding-y"
      id="price"
    >
      <h2>Подберите комфортный для себя тариф</h2>
      <ul className="price-list">
        <li className="discount">
          <p className="gradient-text">Бесплатно в течение</p>
          <div className="discount-time-left">
            <TimeUnit value={timeLeft.months} label="месяцa" />
            <p>:</p>
            <TimeUnit value={timeLeft.days} label="дней" />
            <p>:</p>
            <TimeUnit value={timeLeft.hours} label="часов" />
            <p>:</p>
            <TimeUnit value={timeLeft.minutes} label="минут" />
            <p>:</p>
            <TimeUnit value={timeLeft.seconds} label="секунд" />
          </div>
        </li>
        <li className="tariff">
          <div className="tariff-text">
            <h3>Эконом</h3>
            <ul className="tariff-text-services">
              <li>
                <IoIosCheckmarkCircle />
                Пункт 1
              </li>
              <li>
                <IoIosCheckmarkCircleOutline />
                Пункт 2
              </li>
              <li>
                <IoIosCheckmarkCircleOutline />
                Пункт 3
              </li>
            </ul>
          </div>

          <div className="tariff-price-container">
            <div>
              <price>100</price>
              <p>руб/мес</p>
            </div>
            <button>Выбрать</button>
          </div>
        </li>
        <li className="tariff">
          <div className="tariff-text">
            <h3>Стандарт</h3>
            <ul className="tariff-text-services">
              <li>
                <IoIosCheckmarkCircle />
                Пункт 1
              </li>
              <li>
                <IoIosCheckmarkCircle />
                Пункт 2
              </li>
              <li>
                <IoIosCheckmarkCircleOutline />
                Пункт 3
              </li>
            </ul>
          </div>

          <div className="tariff-price-container">
            <div>
              <price className="gradient-text">250</price>
              <p>руб/мес</p>
            </div>
            <button className="btn-filled">Выбрать</button>
          </div>
        </li>
        <li className="tariff">
          <div className="tariff-text">
            <h3>Премиум</h3>

            <ul className="tariff-text-services">
              <li>
                <IoIosCheckmarkCircle />
                Пункт 1
              </li>
              <li>
                <IoIosCheckmarkCircle />
                Пункт 2
              </li>
              <li>
                <IoIosCheckmarkCircle />
                Пункт 3
              </li>
            </ul>
          </div>

          <div className="tariff-price-container">
            <div>
              <price>500</price>
              <p>руб/мес</p>
            </div>
            <button>Выбрать</button>
          </div>
        </li>
      </ul>
    </section>
  );
};

export default Price;
